import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

export const routes: Routes = [

  {
    path: '',

    loadChildren: () => import('./landingpage/landingpage.module')
      .then(m => m.LandingpageModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module')
      .then(m => m.UserModule),
  },

  {
    path: 'product-wizard',
    loadChildren: () => import('./product-wizard/product-wizard.module')
      .then(m => m.ProductWizardModule),
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module')
      .then(m => m.ProductModule),
  },
  {
    path: 'progress',
    loadChildren: () => import('./progress/progress.module')
      .then(m => m.ProgressModule),
  },
  {
    path: 'product-info',
    loadChildren: () => import('./product/product-info/product-info.module')
      .then(m => m.ProductInfoModule),
  },

  {
    path: 'new-module',
    loadChildren: () => import('./new-module/new-module.module')
      .then(m => m.NewModuleModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./setting/setting.module')
      .then(m => m.SettingModule),
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pricing/pricing.module')
      .then(m => m.PricingModule),
  },
  {
    path: 'review',
    loadChildren: () => import('./review/review.module')
      .then(m => m.ReviewModule),
  },
  {
    path: 'review-service',
    loadChildren: () => import('./review-service/review-service.module')
      .then(m => m.ReviewServiceModule),
  },
  {
    path: 'products',
    loadChildren: () => import('./review-service/products/products.module')
      .then(m => m.ProductsModule),
  },
  {
    path: 'advertise-wall',
    loadChildren: () => import('./advertise-wall/advertise-wall.module')
      .then(m => m.AdvertiseWallModule),
  },
  {
    path: 'linkedin',
    loadChildren: () => import('./review-service/email-linkedin-auth/email-linkedin-auth.module')
      .then(m => m.EmailLinkedinAuthModule),
  },
  {
    path: 'user-settings',
    loadChildren: () => import('./user/settings-user/settings-user.module')
      .then(m => m.SettingsUserModule),
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
  useHash: false,


};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
